<template>
  <div class="frequently-box" v-if="faqData && faqData.length > 0">
    <div class="sf-accordion has-chevron">
      <div class="product-accordion" v-for="faq in faqData" :key="faq.id">
        <div class="accordion-label" @click="handleAccordionOpen(faq)">
          <div class="item__header">
            {{ faq.question }}
          </div>
        </div>
        <div class="accordion-disc">
          {{ faq.answer }}
          <div class="like-content">
            <SfButton
              class="like-button"
              @click.native="likeFaq(faq)"
              :id="`click-like-${faq.id}`"
              :disabled="buttonDisabled || btnDisableCheck(faq.id, product.sku, 'like')"
            >
              <img src="/assets/icons/faqlike.svg" :alt="$t('like')">
              <span>{{ faq.likes }}</span>
            </SfButton>
            <SfButton
              class="like-button rotate"
              @click.native="dislikeFaq(faq.id)"
              :id="`click-dislike-${faq.id}`"
              :disabled="btnDisableCheck(faq.id, product.sku, 'dislike')"
            >
              <img src="/assets/icons/faqlike.svg" :alt="$t('dislike')">
              <span>{{ faq.dislikes }}</span>
            </SfButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { scrollIntoView } from '~/theme/directives/scrollIntoView';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import { isServer } from '@vue-storefront/core/helpers';

export default {
  name: 'MProductAdditionalInfo',
  directives: {
    scrollIntoView
  },
  components: {
    SfButton
  },
  data () {
    return {
      buttonDisabled: false
    };
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      faqData: 'faq/getFaqData'
    })
  },
  methods: {
    btnDisableCheck (id, sku, type) {
      const storeFaqs = JSON.parse(localStorage.getItem('_store_faqs')) || [];
      return storeFaqs.some(faq => (
        faq.id === id &&
        faq.product_sku === sku &&
        faq.type === type
      ));
    },
    async likeFaq (faqData) {
      this.buttonDisabled = true
      const storeFaqs = JSON.parse(localStorage.getItem('_store_faqs')) || [];
      const matchStoreFaqs = storeFaqs.find(faq => (
        faq.id === faqData.id &&
        faq.product_sku === this.product.sku &&
        faq.type === 'like'
      ));

      if (!matchStoreFaqs) {
        try {
          const response = await this.$store.dispatch('faq/like', { faqId: faqData.id });
          storeFaqs.push({ id: faqData.id, product_sku: this.product.sku, type: 'like' });
          localStorage.setItem('_store_faqs', JSON.stringify(storeFaqs));
          const { code, result } = response;
          const counterElement = document.getElementById(`click-like-${faqData.id}`);
          counterElement.querySelector('span').innerHTML = code === 200 ? result[0].likes : '';
        } catch (error) {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Something went wrong. Try again in a few seconds.'),
            action1: { label: this.$t('OK') }
          });
        }
      }
      this.buttonDisabled = false
    },
    handleAccordionOpen (faq) {
      const faqId = faq.id;
      const storeFaqs = JSON.parse(localStorage.getItem('_store_faqs')) || [];
      const matchStoreFaqs = storeFaqs.find(faq => (
        faq.id === faqId &&
        faq.product_sku === this.product.sku &&
        faq.type === 'view'
      ));
      if (!matchStoreFaqs) {
        const accordionData = document.querySelector(`.is-open-${faqId}`);
        if (!accordionData) {
          const newFaq = { id: faqId, product_sku: this.product.sku, type: 'view' };
          storeFaqs.push(newFaq);
          localStorage.setItem('_store_faqs', JSON.stringify(storeFaqs));
          this.$store.dispatch('faq/view', { faqId });
        }
      }
    },
    dislikeFaq (faqId) {
      const storeFaqs = JSON.parse(localStorage.getItem('_store_faqs')) || [];
      const matchStoreFaqs = storeFaqs.find(faq => (
        faq.id === faqId &&
        faq.product_sku === this.product.sku &&
        faq.type === 'dislike'
      ));

      if (!matchStoreFaqs) {
        this.$emit('setCurrentSelectFaq', faqId);
        this.$store.dispatch('ui/toggleDislikeSwitcher', true);
      }
    },
    onEscapePress () {
      this.closeDislikeModal();
    },
    closeDislikeModal () {
      this.$store.dispatch('ui/toggleDislikeSwitcher', false)
    },
    openAccordionsFaq () {
      const faqElements =
        document.querySelectorAll('.accordion-label');
      if (faqElements) {
        faqElements.forEach((faqCollapse) => {
          faqCollapse.addEventListener('click', () => {
            const { parentElement } = faqCollapse;
            if (parentElement.classList.contains('active')) {
              parentElement.classList.remove('active');
            } else {
              parentElement.classList.add('active');
            }
          });
        });
      }
    }
  },
  watch: {
    product: {
      handler (nV) {
        this.reviewsSku = nV.parentSku;
      },
      immediate: true
    }
  },
  mounted () {
    if (isServer) return;
    setTimeout(() => this.openAccordionsFaq(), 200);
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/variables";
.frequently-box {
  padding: 0 var(--spacer-15);
  h2 {
      margin: 0 0 var(--spacer-30);
      font-weight: var(--font-weight--medium);
      font-size: var(--font-size--3xl);
      text-align: center;
  }
  @include for-mobile {
    padding:0 var(--spacer-20);
    margin-top: 25px;
  }
  .like-content {
    display: flex;
    .like-button {
      margin: 15px 10px 0 0;
      display: flex;
      font-weight: 600;
      align-items: center;
      font-size: 19px;
      padding: 13px 20px;
      &.rotate {
        img {
          transform: rotate(180deg);
        }
      }
      img {
        margin-right: 10px;
      }
    }
  }
  .sf-accordion {
    border: 1px solid var(--c-light);
    padding: var(--spacer-10) var(--spacer-20);
    border-radius: 7px;
    .accordion-disc {
      max-height: 0;
      overflow: hidden;
      border: 0;
      padding: 0;
      font: var(--accordion-item-content-font,var(--accordion-item-content-font-weight,var(--font-weight--light)) var(--accordion-item-content-font-size,var(--font-size--base))/var(--accordion-item-content-font-line-height,1.6) var(--accordion-item-content-font-family,var(--font-family--primary)));
      color: var(--c-text);
    }
    .product-accordion {
      .item__header {
        border-bottom: 1px solid var(--c-light);
        position: var(--relative);
        font-family: var(--font-family--primary);
        font-weight: var(--font-weight--medium);
        padding: 1.2rem 0;
        font-size: 18px;
        color: var(--c-text-primary);
        @include for-mobile {
          padding-right: var(--spacer-25);
          font-size: var(--font-size--base);
        }
        cursor: pointer;
        &::before {
          height: 22px;
          width: 2px;
          background: var(--c-true-black);
          right: 10px;
          top: 18px;
          transform: none;
          content: '';
          position: var(--absolute);
          @include for-mobile {
            top: 1.063rem;
          }
        }
        &::after {
          height: 2px;
          width: 22px;
          background: var(--c-true-black);
          right: 0;
          top: 28px;
          transform: none;
          content: '';
          position: var(--absolute);
          @include for-mobile {
            top: 1.688rem;
          }
        }
      }
      &.active {
        .item__header {
          border: 0;
          color: var(--c-primary);
          &::before {
            transform: translate3d(var(--chevron-translateX, 0), var(--chevron-translateY, -50%), 0) rotate(var(--chevron-rotate, 45deg));
            right: 9px;
            top: 30px;
          }
          &::after {
            transform: translate3d(var(--chevron-translateX, 0), var(--chevron-translateY, -50%), 0) rotate(var(--chevron-rotate, 45deg));
            right: -1px;
            top: 30px;
          }
        }
        .accordion-disc {
          max-height: 1500px;
          border: 0;
          border-bottom: 1px solid var(--c-light);
          padding-bottom: var(--spacer-20);
        }
      }
      &:last-child {
          .item__header, .accordion-disc {
            border: 0;
          }
        }
    }
  }
}
#product {
  .m-modal-dislike {
    @include for-mobile {
      z-index: 999999;
      position: relative;
    }
    .sf-bar {
      display:none;
    }
    .sf-modal__close {
      .sf-icon {
          width: 25px;
          height: auto;
        }
      @include for-mobile {
        display: block !important;
      }
    }
    .sf-textarea {
      display: flex;
      flex-direction: column;
      margin: 0;
      textarea {
        box-sizing: border-box;
        width: 100%;
        border: solid 1px var(--c-true-black);
        height: 110px;
        font-family: var(--font-family--primary);
        font-size: var(--font-size--lg);
      }
    }
    .form__element {
      height: auto;
      margin-bottom: var(--spacer-10);
    }
    .sf-modal__content {
      .sf-input__label, .sf-textarea__label {
        position: static;
        transform: none;
        color: var(--c-true-black);
        text-transform: underline;
        order: -1;
        margin-bottom: var(--spacer-10);
        font-size: var(--font-size--base);
        font-family: var(--font-family--primary);
      }
      .sf-input__wrapper {
        display: flex;
        margin: 0;
        flex-direction: column;
        input {
          border: solid 1px var(--c-true-black);
          padding: var(--spacer-10);
        }
      }
      .form__submit {
        margin-top: var(--spacer-10);
      }
      .form {
        padding-top: var(--spacer-30);
        @include for-mobile {
          padding-top: var(--spacer-10);
        }
      }
    }
    .sf-modal__container {
      @include for-mobile {
        width: 90%;
        margin: auto;
        height: auto;
        min-height: unset;
        -webkit-transform: var(--modal-transform);
        top: 50%;
        bottom: inherit;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
